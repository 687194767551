import type { User } from "@civic/auth";
import type {
  CivicApiClientConfig,
  CreateSubOrganisationResponse,
  GetWalletsResponse,
  UnknownObject,
} from "../../types.js";

const DEFAULT_BASE_API =
  process.env.NEXT_PUBLIC_WALLET_API_BASE_URL ||
  "https://api.civic.com/turnkey";

export class CivicTurnkeyApiClient {
  readonly config: CivicApiClientConfig & { endpoints: { wallet: string } };

  constructor(
    readonly user: User<UnknownObject>,
    config?: CivicApiClientConfig,
  ) {
    this.config = {
      ...(config ?? {}),
      endpoints: {
        wallet: DEFAULT_BASE_API,
        ...config?.endpoints,
      },
    };
  }

  async createWallet(): Promise<CreateSubOrganisationResponse> {
    const response = await fetch(this.config.endpoints.wallet + "/wallet", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.user.idToken}`,
      },
      body: JSON.stringify({}),
    });

    if (!response.ok) {
      throw new Error(`Failed to create wallet: ${response.statusText}`);
    }

    const walletResponse: { result: CreateSubOrganisationResponse } =
      await response.json();

    return walletResponse.result;
  }

  async getWallets(): Promise<GetWalletsResponse | null> {
    const url = this.config.endpoints.wallet + "/wallet";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.user.idToken}`,
      },
    });
    if (!response.ok) {
      console.error(await response.json());
      throw new Error(`Failed to get wallets: ${response.statusText}`);
    }

    const walletsResponse: { result: { wallets: GetWalletsResponse } | null } =
      await response.json();

    return walletsResponse.result?.wallets ?? null;
  }
}
