"use client";
import { useEffect, useState } from "react";

const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length !== 2) return null;
  return parts.pop()?.split(";").shift() ?? null;
};

export const useCookie = (name: string): string | null => {
  const [cookieValue, setCookieValue] = useState(getCookie(name));

  useEffect(() => {
    const interval = setInterval(() => {
      const newCookieValue = getCookie(name);
      if (newCookieValue !== cookieValue) {
        setCookieValue(newCookieValue); // Update state when cookie changes
      }
    }, 1000); // Poll every 1 second (adjust if necessary)

    return () => clearInterval(interval); // Clean up the interval
  }, [cookieValue]);

  return cookieValue;
};
