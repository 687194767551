export const makeLogger = (debug: boolean) => ({
  debug: (...args: unknown[]) => {
    if (debug) console.debug(...args);
  },
  log: (...args: unknown[]) => {
    if (debug) console.log(...args);
  },
  warn: (...args: unknown[]) => {
    console.warn(...args);
  },
  error: (...args: unknown[]) => {
    console.error(...args);
  },
});
