"use client";
import {
  type AuthContextType,
  useUser as useUserInternal,
} from "@civic/auth/react";
import React, {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useSetUpWagmiConnector } from "../hooks/useSetUpWagmiConnector.js";
import { useCreateWalletAccount } from "../hooks/useCreateWalletAccount.js";
import { useWeb3Client } from "../hooks/turnkey/useWeb3Client.js";
import type { Web3ProviderProps, Web3UserContextType } from "../types.js";

// The Web3UserProvider component is a wrapper around the CivicAuthProvider component that provides
// an augmented user object to the rest of the application, with web3 wallet information.
// If the user already has an embedded wallet, the wallet address and viem client are added to the user object.
// NOTE: When using wagmi, these augmented user fields are not necessary, as the wagmi connector
// provides the same information and can be directly injected into the wagmi config
// If the user does not yet have an embedded wallet, a createWallet function is added to the user object.
const defaultContext = {
  isAuthenticated: false,
  isLoading: false,
  error: null,
  signIn: () => {
    throw new Error("signIn not implemented");
  },
  signOut: () => {
    throw new Error("signOut not implemented");
  },
  user: null,
  walletAddress: null,
  wallet: null,
  createWallet: () => {
    throw new Error("createWallet not implemented");
  },
  walletCreationInProgress: false,
};
const Web3UserContext = createContext<Web3UserContextType>(defaultContext);
export const useUser = () => useContext(Web3UserContext);

type InternalWeb3ProviderProps = {
  registerLogoutCallback: (callback: () => Promise<void>) => void;
};

export const Web3UserProvider: FC<
  PropsWithChildren & Web3ProviderProps & InternalWeb3ProviderProps
> = ({ children, registerLogoutCallback, ...props }) => {
  const internalUserContext = useUserInternal();

  const web3Client = useWeb3Client(props, () => internalUserContext.signOut());

  const { createWallet, createWalletFinished, walletCreationInProgress } =
    useCreateWalletAccount(web3Client);

  useSetUpWagmiConnector(web3Client, createWalletFinished);

  // register the logout callback
  useEffect(() => {
    if (web3Client) {
      registerLogoutCallback(async () => {
        // TODO check if we need to manually remove this entry when the user logs out
        localStorage.removeItem("@turnkey/current_user");
        await web3Client.disconnect();
      });
    }
  }, [web3Client, registerLogoutCallback]);

  const web3User: Web3UserContextType = useMemo(() => {
    const isAuthenticated = !!internalUserContext.user;
    const authContext: AuthContextType = {
      signIn: internalUserContext.signIn,
      isAuthenticated,
      isLoading: internalUserContext.isLoading,
      error: internalUserContext.error,
      signOut: internalUserContext.signOut,
    };

    if (!web3Client) {
      return {
        ...authContext,
        wallet: null,
        walletAddress: null,
        // we override the civic auth isLoading state as we're still loading until we have a web3 client
        isLoading: internalUserContext?.user ? true : false,
        user: null,
        walletCreationInProgress,
      };
    }

    // we don't have an address so we're a new user and need to expose the createWallet function
    if (!web3Client.address) {
      return {
        ...authContext,
        isLoading: false,
        wallet: web3Client.client,
        walletAddress: null,
        createWallet,
        user: internalUserContext.user,
        walletCreationInProgress,
      };
    }

    // this is an existing user with a wallet
    return {
      ...authContext,
      isLoading: false,
      walletAddress: web3Client.address,
      wallet: web3Client.client,
      user: internalUserContext.user,
      walletCreationInProgress,
    };
  }, [
    internalUserContext.user,
    createWallet,
    web3Client?.client,
    web3Client?.address,
  ]);

  return (
    <Web3UserContext.Provider value={web3User}>
      {children}
    </Web3UserContext.Provider>
  );
};
