import { useCallback, useState } from "react";
import type { Web3Client } from "../lib/Web3Client.js";

// a callback that creates a wallet account for the user and relying party,
// and triggers a login
export const useCreateWalletAccount = (web3Client: Web3Client | null) => {
  const [walletCreationInProgress, setWalletCreationInProgress] =
    useState(false);
  const [createWalletFinished, setCreateWalletFinished] = useState(false);
  const createWallet = useCallback(async () => {
    setWalletCreationInProgress(true);
    await web3Client?.createWallet();
    setCreateWalletFinished(true);
  }, [web3Client]);
  return { createWallet, createWalletFinished, walletCreationInProgress };
};
