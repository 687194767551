import { useUser as useUserInternal } from "@civic/auth/react";
import type { CivicApiClientConfig } from "../../types.js";
import { TurnkeyWeb3Client } from "../../lib/turnkey/TurnkeyWeb3Client.js";
import type { Web3Client } from "../../lib/Web3Client.js";
import { useTurnkey } from "@turnkey/sdk-react";
import { useEffect, useState } from "react";
import { useCookie } from "../useCookie.js";
import type { TurnkeyUser } from "../../lib/turnkey/turnkeyApiClient.js";

// The turnkey API is unstable when several requests come in at once, so
// we keep track of the first successful client to avoid creating multiple clients.
// We use this instead of useMemo as useMemo will still be called twice in dev environments
// However the downside is if the parameters change, the client will not be recreated.
let firstSuccessful: Web3Client;

// This hook returns a web3client object that can be used to interact with the user's wallet.
// Web3Client is a generic interface for embedded wallets provided by civic,
// This hook creates a turnkey version.
export const useWeb3Client = (
  config?: CivicApiClientConfig,
  onSessionEnd?: () => void,
): Web3Client | null => {
  const { turnkey, authIframeClient } = useTurnkey();
  const { user } = useUserInternal<TurnkeyUser>();
  const [web3Client, setWeb3Client] = useState<Web3Client | null>(null);
  const [isBuilding, setIsBuilding] = useState(false);
  const walletCookie = useCookie("civic-embedded-wallet");

  useEffect(() => {
    if (!user?.idToken || !turnkey || !authIframeClient || isBuilding) return;

    setIsBuilding(true);
    // TODO remove this once we are confident the config is not causing re-renders
    console.log("Rebuilding Web3Client with user and config", {
      turnkey,
      authIframeClient,
      user,
      config,
    });
    TurnkeyWeb3Client.buildReact(
      {
        sdk: turnkey,
        iframeClient: authIframeClient,
      },
      user,
      config ?? {},
      onSessionEnd,
    )
      .then((client) => {
        // if there is already a web3 client set, do not reset it
        // This avoids creating multiple clients, which causes errors to be thrown in turnkey
        if (
          !firstSuccessful ||
          (firstSuccessful.client === null && client.client !== null)
        ) {
          console.log("Web3Client created", client);
          firstSuccessful = client;
          setWeb3Client(client);
        } else {
          console.log("Web3Client already created, not setting");
        }
      })
      .catch((e) => {
        // Note, this appears to happen in the turnkey API if two oauth attempts come in at the same time
        // for the same user - since this can happen, we could consider squashing this here.
        console.error("Error creating web3 client", e);
      });
    // the dependency array just contains boolean values for whether the dependencies are ready
    // this is to avoid issues when hashing the actual dependencies
    // warning, this may cause issues if the dependencies change
  }, [
    user,
    !!turnkey,
    !!authIframeClient,
    JSON.stringify(config),
    walletCookie,
    isBuilding,
  ]);

  return web3Client;
};
