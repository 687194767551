export { embeddedWallet } from "./lib/turnkey/TurnkeyConnector.js";
export { isReady } from "./lib/wagmiUtils.js";
export { isNewUser, userHasWallet } from "./lib/walletUtils.js";
export { CivicAuthProvider } from "./components/CivicAuthProvider.js";
export type {
  Web3User,
  NewWeb3UserContext,
  ExistingWeb3UserContext,
} from "./types.js";

export type {
  OAuthTokens,
  CodeVerifier,
  UserStorage,
  CookieConfig,
  TokensCookieConfig,
  Tokens,
  Endpoints,
  Config,
  User,
  DisplayMode,
  ForwardedTokens,
  AuthStorage,
} from "@civic/auth";
