import type {
  ExistingWeb3UserContext,
  NewWeb3UserContext,
  Web3UserContextType,
} from "../types.js";

export const isNewUser = (
  userContext: Web3UserContextType,
): userContext is NewWeb3UserContext =>
  !("walletAddress" in userContext && userContext.walletAddress);

export const userHasWallet = (
  userContext: Web3UserContextType,
): userContext is ExistingWeb3UserContext => !isNewUser(userContext);
