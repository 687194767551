import { ChainNotConfiguredError, createConnector } from "wagmi";
import {
  type Address,
  type Chain,
  getAddress,
  type LocalAccount,
  SwitchChainError,
  type Transport,
  type WalletClient,
} from "viem";
import type { TurnkeyConnectorProperties } from "../../types.js";
import { TurnkeyWalletProvider } from "./TurnkeyWalletProvider.js";
import { makeLogger } from "./logger.js";

export const embeddedWallet = (config: ConnectorConfig = {}) =>
  TurnkeyConnector(config);

// Expose this connector's name and type as Civic to avoid exposing the internal provider
TurnkeyConnector.type = "civic" as const;

type ConnectorConfig = {
  debug?: boolean;
};
const defaultConfig: Required<ConnectorConfig> = {
  debug: false,
};

/**
 * Wagmi Turnkey connector implementation.
 * Used only for wagmi clients. Non-wagmi clients can use the viem client provided by TurnkeyWeb3Client
 */
export function TurnkeyConnector(config: ConnectorConfig = {}) {
  const connectorConfig = { ...defaultConfig, ...config };
  const TURNKEY_DISCONNECTED = "turnkey.disconnected";
  type StorageItem = { "turnkey.disconnected": true };

  let walletClient: WalletClient<Transport, Chain, LocalAccount> | undefined =
    undefined;
  let provider: TurnkeyWalletProvider | undefined;

  const logger = makeLogger(connectorConfig.debug);

  return createConnector<
    TurnkeyWalletProvider,
    TurnkeyConnectorProperties,
    StorageItem
  >((config) => ({
    id: "civic",
    name: "Civic",
    type: TurnkeyConnector.type,
    icon: "https://www.civic.com/wp-content/uploads/2024/06/favicon@16x-150x150.png",
    ready: false,

    setViemClient(viemClient: WalletClient<Transport, Chain, LocalAccount>) {
      walletClient = viemClient;
      this.ready = true;
      logger.debug("TurnkeyConnector ready = true. emitting change event...");
      config.emitter.emit("change", {
        // Include current state to ensure wagmi updates everything
        chainId: viemClient.chain.id,
        accounts: [viemClient.account.address], // or actual accounts if you have them
      });
    },

    /**
     * Setup the connector. This is called once when the app is loaded.
     */
    async setup() {
      logger.debug(`TurnkeyConnector running setup`);
      if (!walletClient) {
        logger.warn("setup: TurnkeyConnector called before walletClient set");
      }
    },

    /**
     * Connect to the provider. This is called when the user clicks the connect button.
     */
    async connect({ chainId } = {}) {
      logger.log("TurnkeyConnector connect: walletClient", walletClient);
      if (!walletClient) {
        throw new Error(
          "connect: TurnkeyConnector called before walletClient set",
        );
      }

      try {
        provider = new TurnkeyWalletProvider();

        provider.setClient(walletClient);

        const accounts = (await this.getAccounts()) as readonly Address[];
        if (!accounts?.length) {
          throw new Error("No accounts found in Turnkey");
        }

        // Switch chain if needed
        let currentChainId = (await this.getChainId()) as number;
        if (chainId && currentChainId !== chainId) {
          const chain = await this.switchChain!({ chainId });
          currentChainId = chain?.id ?? currentChainId;
        }

        // Add shim signaling connector is connected
        await config.storage?.removeItem(TURNKEY_DISCONNECTED);

        config.emitter.emit("connect", { accounts, chainId: currentChainId });
        return { accounts, chainId: currentChainId };
      } catch (e) {
        logger.error("TurnkeyConnector connect: error", e);
        throw e;
      }
    },

    /**
     * Disconnect from the provider. This is called when the user clicks the disconnect button.
     */
    async disconnect() {
      logger.debug(`TurnkeyConnector running disconnect`);

      // Add shim signaling connector is disconnected
      await config.storage?.setItem(TURNKEY_DISCONNECTED, true);

      // Clear the provider
      // provider = undefined;
      config.emitter.emit("disconnect");
    },

    /**
     * Get the provider.
     * This can return undefined if the provider is not initialized.
     */
    async getProvider() {
      logger.debug(`TurnkeyConnector running getProvider`);

      if (!provider) throw new Error("Turnkey provider is not initialized");

      return provider;
    },

    /**
     * Get the current accounts.
     */
    async getAccounts() {
      logger.debug(`TurnkeyConnector running getAccounts`);

      if (!provider)
        throw new Error(
          "TurnkeyConnector: Turnkey provider is not initialized",
        );

      const account = provider.getAccount();
      return account ? [account.address] : [];
    },

    /**
     * Get the current chain ID.
     */
    async getChainId() {
      logger.debug(`TurnkeyConnector running getChainId`);

      if (!provider)
        throw new Error(
          "TurnkeyConnector: Turnkey provider is not initialized",
        );

      return provider.getChainId();
    },

    /**
     * Check if the user is authorized.
     */
    async isAuthorized() {
      logger.debug(`TurnkeyConnector running isAuthorized`);

      if (!provider) return false;

      try {
        const isDisconnected =
          await config.storage?.getItem(TURNKEY_DISCONNECTED);
        if (isDisconnected) return false;

        const accounts = await this.getAccounts();
        logger.debug("isAuthorized accounts", accounts);
        return !!accounts.length;
      } catch (e) {
        logger.debug("isAuthorized error", e);
        return false;
      }
    },

    /**
     * Switch the chain.
     */
    async switchChain({ chainId }) {
      logger.debug(`TurnkeyConnector running switchChain: chainId ${chainId}`);

      if (!provider)
        throw new Error(
          "TurnkeyConnector: Turnkey provider is not initialized",
        );

      const chain = config.chains.find((x) => x.id === chainId);
      if (!chain) throw new SwitchChainError(new ChainNotConfiguredError());
      logger.debug("TurnkeyConnector switchChain chain", chain);

      provider.setChain(chain);

      this.onChainChanged(chainId.toString());

      logger.debug("TurnkeyConnector switchChain event fired", chain);

      config.emitter.emit("change", { chainId });
      return chain;
    },

    /**
     * Accounts change event handler.
     */
    async onAccountsChanged(accounts) {
      logger.debug("TurnkeyConnector onAccountsChanged", accounts);
      if (accounts.length === 0) this.onDisconnect();
      else {
        config.emitter.emit("change", {
          accounts: accounts.map((x) => getAddress(x)),
        });
      }
    },

    /**
     * Chain change event handler.
     */
    onChainChanged(chain) {
      logger.debug("TurnkeyConnector onChainChanged", chain);
      const chainId = Number(chain);
      config.emitter.emit("change", { chainId });
    },

    /**
     * Connect event handler.
     */
    async onConnect(connectInfo) {
      logger.debug("TurnkeyConnector onConnect", connectInfo);
      const accounts = await this.getAccounts();
      if (accounts.length === 0) return;

      const chainId = Number(connectInfo.chainId);
      config.emitter.emit("connect", { accounts, chainId });
    },

    /**
     * Disconnect event handler.
     */
    async onDisconnect() {
      logger.debug("TurnkeyConnector onDisconnect");
      config.emitter.emit("disconnect");
    },
  }));
}
