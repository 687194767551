/// A wagmi wallet provider that wraps a turnkey viem client.
/// Used internally only (by the Turnkey Connector), should not be exported.
import type { ViemClientProvider } from "../../types.js";
import {
  type Chain,
  createPublicClient,
  createWalletClient,
  type LocalAccount,
  type PublicClient,
  type SendTransactionParameters,
  type SignableMessage,
  type SignTypedDataParameters,
  type Transport,
  type WalletClient,
} from "viem";
import { viemTransport } from "./turnkeyApiClient.js";

const cloneWalletClientWithNewChain = (
  client: WalletClient<Transport, Chain, LocalAccount>,
  chain: Chain,
) => {
  return createWalletClient({
    chain,
    transport: viemTransport,
    account: client.account,
  });
};

export class TurnkeyWalletProvider
  implements ViemClientProvider<Transport, Chain, LocalAccount, undefined>
{
  // used for wallet-specific operations
  client: WalletClient<Transport, Chain, LocalAccount> | undefined;
  // used for unauthenticated RPC calls
  publicClient: PublicClient<Transport, Chain, LocalAccount> | undefined;

  setClient(client: WalletClient<Transport, Chain, LocalAccount>) {
    this.client = client;
    this.publicClient = createPublicClient({
      chain: client.chain,
      transport: viemTransport,
    });
  }

  getChainId(): number {
    console.log("TurnkeyProvider getChainId");
    if (!this.client) throw new Error("Turnkey provider is not initialized");
    if (!this.client.chain)
      throw new Error("Turnkey client is not connected to a chain");
    return this.client.chain.id;
  }

  getAccount() {
    console.log("TurnkeyProvider getAccount");
    if (!this.client) throw new Error("Turnkey provider is not initialized");
    return this.client.account;
  }

  setChain(chain: Chain) {
    console.log("TurnkeyProvider setChain", chain);
    if (!this.client) {
      throw new Error("Turnkey provider is not initialized");
    }
    this.setClient(cloneWalletClientWithNewChain(this.client, chain));
  }

  enable(): Promise<string[]> {
    console.log("Turnkey Provider enable");
    if (!this.client) throw new Error("Turnkey provider is not initialized");
    return this.client.getAddresses();
  }

  // RPC method params are not typed at present

  async request({
    method,
    params,
  }: {
    method: string;
    params: unknown[];
  }): Promise<unknown> {
    console.log("Turnkey Provider request", { method, params });
    if (!this.client || !this.publicClient)
      throw new Error("Turnkey provider is not initialized");

    switch (method) {
      // **Wallet-Specific Methods**
      case "eth_accounts":
        return this.client.getAddresses();
      case "eth_chainId":
        // eslint-disable-next-line no-case-declarations
        const chainId = await this.client.getChainId();
        return `0x${chainId.toString(16)}`;
      case "eth_sendTransaction":
        // eslint-disable-next-line no-case-declarations
        const [sendTransactionParameters] = params as [
          SendTransactionParameters,
        ];
        return this.client.sendTransaction({
          ...sendTransactionParameters,
          value: BigInt(sendTransactionParameters.value ?? 0),
        });
      case "eth_sign":
      case "personal_sign":
        // eslint-disable-next-line no-case-declarations
        const [signMessageParameters] = params as [SignableMessage];
        return this.client.signMessage({ message: signMessageParameters });
      case "eth_signTypedData":
      case "eth_signTypedData_v4":
        // eslint-disable-next-line no-case-declarations
        const [typedData] = params as [SignTypedDataParameters];
        return this.client.signTypedData(typedData);
      // **All Other Methods**
      default:
        console.log("TurnkeyProvider request default", { method, params });
        // Use PublicClient for read-only or network calls
        return this.publicClient.request({
          method,
          params,
        } as Parameters<typeof this.publicClient.request>[0]);
    }
  }
}
