import { type Connector, useConnectors } from "wagmi";
import { useEffect } from "react";
import type { ViemConnector } from "../types.js";
import type { Web3Client } from "../lib/Web3Client.js";

const isViemConnector = (
  connector: Connector,
): connector is Connector & ViemConnector => "setViemClient" in connector;

// find the embedded wallet connector, and, if it exists, pass the viem signer from the web3Client to it.
// This allows us to register a wallet connector outside of the react context using wagmi createConfig.
export const useSetUpWagmiConnector = (
  web3Client: Web3Client | null,
  createWalletFinished: boolean,
): (Connector & ViemConnector) | undefined => {
  const wagmiConnectors = useConnectors();

  const connector = wagmiConnectors.find(isViemConnector);

  useEffect(() => {
    console.log("useSetUpWagmiConnector ", { connector, web3Client });
    if (connector && web3Client?.client) {
      console.log("Setting viem client with ", web3Client.client.account);
      connector.setViemClient(web3Client.client);
    }
  }, [connector, web3Client?.client, createWalletFinished]);

  return connector;
};
