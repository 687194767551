import { useTurnkey } from "@turnkey/sdk-react";
import { useMemo } from "react";
import { bytesToHex } from "@noble/hashes/utils";
import { sha256 } from "@noble/hashes/sha2";

// Given a loaded turnkey iframe, get its public key,
// to be used by the oauth process with civic-pass,
// in order to secure the communication between turnkey and the user
export const useTurnkeyNonce = (): string | null => {
  const { authIframeClient } = useTurnkey();
  return useMemo(() => {
    if (!authIframeClient?.iframePublicKey) return null;
    return bytesToHex(sha256(authIframeClient?.iframePublicKey));
  }, [authIframeClient?.iframePublicKey]);
};
